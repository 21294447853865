import Vue from "vue";
import Component from 'vue-class-component'
import * as Http from './../shared/http';

@Component
export default class ConvertUser extends Vue {
    returnUrl: string;

    public createNewAccount(): void {
        var redirectUrl = this.getBaseUrl() + "/newaccount";
        window.location.href = redirectUrl;
    }

    public upgradeExistingAccount(): void {
        var redirectUrl = this.getBaseUrl() + "/convertaccount";
        window.location.href = redirectUrl;
    }

    private getBaseUrl(): string {
        return window.location.protocol + "//" + window.location.host;
    }
}