import Vue from "vue";
import Component from 'vue-class-component';
import * as Http from './../shared/http';
import Product from './product';

@Component
export default class productPicker extends Vue {
	isLoading: boolean = false;
	products: Product[];

	created() {
		this.products = [];
		this.isLoading = true;

		Http.request("/product", "GET")
			.then(result => {
				result.map(r => this.products.push(new Product(r.name, r.url)))
				this.isLoading = false;
			})
			.catch(error => {
				this.$router.push({ name: 'login' });
				this.isLoading = false;
			});
	}
}