import {AppInsights} from "applicationinsights-js"

var instrumentationKey = "af7ceb6b-d321-4c83-b815-53345c726067";
if(document.location.hostname.search('login.cint.com') === 0) {
    instrumentationKey = 'b6a27130-577e-4592-9006-a30a548af697';
}
if(document.location.hostname.search('login-beta.cint.com') === 0 || document.location.hostname.search('login.cintbeta.com') === 0) {
    instrumentationKey = '5291f5b8-7e2b-44db-927d-ff577f97d643';
}
if(document.location.hostname.search('login.cintworks.net') === 0) {
    instrumentationKey = '2ad2dd22-c8d6-4d9a-ae44-5b382a38680b';
}
/* Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key */
AppInsights.downloadAndSetup({ instrumentationKey: instrumentationKey });

function trackPageView(pagename?:string, url?:string, duration?:number) {
    AppInsights.trackPageView(
        pagename, /* (optional) page name */
        url, /* (optional) page url if available */
        duration /* page view duration in milliseconds */
    );
}

export {trackPageView}