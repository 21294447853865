import Vue from "vue";
import Component from 'vue-class-component'
import * as Http from './../shared/http';

@Component
export default class UpgradeAccount extends Vue {
    returnUrl: string;
    upgradeUrl: string;

    created() {
        this.returnUrl = this.$route.params.returnUrl;
        this.upgradeUrl = this.returnUrl ? "/upgradeaccount?returnUrl=" + encodeURIComponent(this.returnUrl) : "/upgradeaccount";
    }
}