import { polyfill } from 'es6-promise'
import Vue from 'vue';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import * as Http from './shared/http';
import * as AppInsights from './shared/appinsights';

import App from './app.vue';
import Footer from './footer.vue';
import Login from './login/login.vue';
import ResetPassword from "./resetpassword/resetPassword.vue";
import RequestPasswordReset from './forgotPassword/requestPasswordReset.vue';
import Confirm from './confirm/confirm.vue';
import ProductPicker from './productPicker/productPicker.vue';
import AccountConverted from './accountConverted/accountConverted.vue';
import CompanyConverted from './companyConverted/companyConverted.vue';
import ConvertUser from './convertUser/convertUser.vue';
import UpgradeAccount from './upgradeAccount/upgradeAccount.vue';
import ChangeProvider from './changeProvider/changeProvider.vue';

polyfill();

new Vue({
    el: '#footer',
    render: h => {
        return h(Footer);
    }
});


const router = new VueRouter({
    routes: [
        { path: '/forgot-password', component: RequestPasswordReset, name: 'requestPasswordReset' },
        { path: '/reset-password/:token', component: ResetPassword },
        { path: '/confirm', component: Confirm, name: 'confirm' },
        { path: '/product', component: ProductPicker, name: 'productPicker' },
        { path: '/account-converted/:returnUrl?', component: AccountConverted },
        { path: '/company-converted/:returnUrl?', component: CompanyConverted },
        { path: '/upgrade-account/:returnUrl?', component: UpgradeAccount },
        { path: '/change-provider/:returnUrl?', component: ChangeProvider },
        { path: '/convert-user', component: ConvertUser, name: 'convertUser' },
        { path: '/:error?', component: Login, name: 'login' },
        { path: '*', redirect: '/' }
    ]
});

Vue.use(VueRouter);
Vue.use(BootstrapVue);

const app = new Vue({ router }).$mount('#app');

AppInsights.trackPageView('/', document.URL)