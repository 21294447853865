import Vue from "vue";
import Component from 'vue-class-component';
import * as Http from './../shared/http';

@Component
export default class ResetPassword extends Vue {
	password: boolean = null;
	token: String = null;
	errorMessage: String = null;
	errorMessages: string[] = null;
	isLoading: boolean = false;
	isRequestValid: boolean = false;
	showPassword: boolean = false;

	created() {
		this.token = this.$route.params.token;
		var json = JSON.stringify({ token: this.token });

		Http.request('/reset-password/validate', 'POST', json)
			.then(result => {
				this.isRequestValid = true;
				this.isLoading = false;
			})
			.catch(error => {
				this.errorMessage = error.message || "Something went wrong. Please retry.";
				this.isRequestValid = false;
				this.isLoading = false;
			});
		this.isRequestValid = false;
		this.errorMessage = null;
		this.isLoading = true;
	}

	resetPassword() {
		var json = JSON.stringify({ token: this.token, newpassword: this.password });
		Http.request('/reset-password/reset', 'POST', json)
			.then(result => {
				this.isLoading = false;
				this.$router.push({ name: 'login', params: { error: 'p' } });
			})
			.catch(error => {
				this.errorMessages = error.message.split(";");
				this.isLoading = false;
			});

		this.errorMessage = null;
		this.errorMessages = null;
		this.isLoading = true;
	}

}