import Vue from "vue";
import Component from 'vue-class-component'
import * as Http from './../shared/http';

@Component
export default class CompanyConverted extends Vue {
	returnUrl: string;

	created() {
		this.returnUrl = this.$route.params.returnUrl;
	}

	continueOn() {
		if (this.returnUrl) {
			window.location.href = this.returnUrl;
		} else {
			this.$router.push({ name: 'productPicker' })
		}
	}
}