import Vue from "vue";
import Component from 'vue-class-component'
import * as Http from './../shared/http';

@Component
export default class Login extends Vue {
    returnUrl: string;
    aadUrl: string;
    username: String = "";
    password: String = "";
    errorMessage: String;
    successMessage: String;
    aadErrorMessage: String;
    supportText: String = "";
    supportLinkText: String = "";
    isLoading: boolean = false;
	isEnabled: boolean = false;
	isUpgradingAccount = false;
    loginFailed: boolean = false;

	login() {
		var json = JSON.stringify({ username: this.username, password: this.password, isUpgradingAccount: this.isUpgradingAccount, returnUrl: this.returnUrl });

        Http.request("/login", "POST", json)
            .then(result => {
                window.location.href = result.url;
                this.isLoading = false;
                this.loginFailed = false;
            })
            .catch(error => {
                this.errorMessage = error.message || "login failed. please reload the page";
                this.isLoading = false;
                this.loginFailed = true;
            });

        this.successMessage = null;
        this.errorMessage = null;
        this.aadErrorMessage = null;
        this.isLoading = true;
    }

    created() {
        Http.request("/appsettings", "GET")
            .then(result => {
                if (result.isAuthenticated === true) {
                    this.$router.push({ name: 'productPicker' })
                }
            });

        this.returnUrl = getParameterByName("returnUrl") || getParameterByName("ReturnUrl")

        this.aadUrl = this.returnUrl ? "/aad?returnUrl=" + encodeURIComponent(this.returnUrl) : "/aad";

        this.errorMessage = this.getError();
        this.successMessage = this.getMessage();
		this.aadErrorMessage = this.getAADError();
		this.isUpgradingAccount = this.getIsUpgradingAccount();

        this.checkFeatureToggles();
        this.setSupportText();
    }

    private checkFeatureToggles(): void {
        Http.request("/reset-password/enabled", "GET")
            .then(result => {
                this.isEnabled = result.result;
            })
            .catch(error => {
                this.isEnabled = false;
            });
    }

    getError() {
        var error = this.$route.params.error;
        switch (error) {
            case "d":
                return "Access denied";
            default:
                return "";
        }
    }

    getAADError() {
        var error = this.$route.params.error;
        switch (error) {
            case "aad5":
                return "Unable to login with your Microsoft account. Please contact your Cint administrator.";
            case "aad6":
                return "Could not log in with your Microsoft account, please try again later.";
            case "aad7":
                return "Failed to setup Microsoft login. Please try again later.";
            default:
                return "";
        }
    }

    getMessage() {
        var error = this.$route.params.error;
        switch (error) {
            case "l":
                return "You have been logged out.";
            case "p":
                return "Your password has been changed.";
            case "c":
                return "Your account has been activated. Please log in to continue.";
            case "upgradeaccount":
                return "Log in with your existing Cint account to connect it to your Microsoft login";
            default:
                return "";
        }
	}

	getIsUpgradingAccount() {
		var error = this.$route.params.error;
		return error == "upgradeaccount";
	}

    setSupportText() {
        var param = this.$route.params.error;
        if (param == "aad4") {
            this.supportText = "Your company does not support signing in with Microsoft. Please contact your Company Administrator";
            return;
        }
    }
}

function getParameterByName(name: string, url: string = null) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}