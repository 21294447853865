import Vue from "vue";
import Component from 'vue-class-component'
import * as Http from './../shared/http';

@Component
export default class ChangeProvider extends Vue {
    returnUrl: string;
    signInWithAADUrl: string;

	created() {
        this.returnUrl = this.$route.params.returnUrl;
        this.signInWithAADUrl = this.returnUrl ? "/changesigninmethod?returnUrl=" + encodeURIComponent(this.returnUrl) : "/changesigninmethod";
	}
}